<template>
  <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar
          dark
          color="teal"
        >
          <v-btn
            icon
            dark
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ item.client }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="item.date">
            {{ item.date | moment("DD.MM.YYYY HH:mm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title class="message-title">{{ item.text }}</v-card-title>
        <v-card-text>
          <div v-for="el in item.details" :key="el.title" class="d-flex">
            <div>{{ el.title }}</div>
            <v-spacer></v-spacer>
            <div>{{ el.value }}</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    isOpenDialog: {
      default: false,
      type: Boolean
    },
    item: {
      default() {return {client: '',details:[]} },
    },
    
  },
  data: () => {
    return {
      dialog: false,
    }
  },
  watch: {
    isOpenDialog() {    
      this.dialog = this.isOpenDialog;
    }
  },
  methods: {
    closeDialog() {
      this.$parent.isOpenDialog = false;
    }
  }
}
</script>

<style>

</style>