<template>
  <div>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <change-expert v-if="$store.getters.isAdmin" />
      <v-tooltip top>
        <template #[`activator`]="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="getMessages"><v-icon>mdi-reload</v-icon></v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="messages"
      :loading="loading"
      :sort-by="'date'"
      :sort-desc="true"
      item-key="id"
      :search="search"
      show-select
      class="elevation-1 cursor-pointer message-table"
      @click:row="openDialog"
    >
      <template #[`header.actions`]>
        <div class="d-flex" @click.stop >
          <v-tooltip top>
            <template #[`activator`]="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="markAsRead" class="mr-2">
                <v-icon>mdi-email-open</v-icon>
              </v-btn>
            </template>
            <span>Отметить выбранные прочитанными</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="isarchive" icon v-bind="attrs" @click="restoreSelected" v-on="on">
                <v-icon>mdi-package-up</v-icon>
              </v-btn>
              <v-btn v-else icon v-bind="attrs" @click="deleteSelected" v-on="on">
                <v-icon>mdi-package-down</v-icon>
              </v-btn>
            </template>
            <span> {{ isarchive ? 'Восстановить выбранные из архива' : 'Отправить выбранные в архив' }}</span>
          </v-tooltip>
        </div>
      </template>
      <template #[`header.name`]>
        <div class="font-weight-regular" v-if="messages.length">
          <v-autocomplete
            v-model="search"
            :items="messages"
            item-text="name"
            item-key="name"
            label="Пользователь"
            class="mb-0"
            clearable
            color="teal"
            item-color="teal teal lighten-1"
          ></v-autocomplete>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex" @click.stop>
          <v-tooltip top>            
            <template #[`activator`]="{ on, attrs }">
              <v-btn icon @click="updateStatus([item.id], !item.read)" v-bind="attrs" v-on="on" class="mr-2">
                <v-icon>{{ item.read ? 'mdi-email' : 'mdi-email-open' }} </v-icon>
              </v-btn>
            </template>
            <span>Отметить {{ item.read ? 'не' : '' }} прочитанным</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="isarchive" icon v-bind="attrs" v-on="on" @click="restoreMessages([item.id])">
                <v-icon>mdi-package-up</v-icon>
              </v-btn>
              <v-btn v-else icon v-bind="attrs" v-on="on" @click="deleteMessages([item.id])">
                <v-icon>mdi-package-down</v-icon>
              </v-btn>
            </template>
            <span>{{ isarchive ? 'Восстановить' : 'Отправить в архив' }}</span>
          </v-tooltip>
        </div>
      </template> 
      <template #[`item.text`]="{ item }">
        <div :class="item.read ? '' : 'font-weight-bold'" class="text-width">
        {{ item.text }}
        </div>
      </template>
      <template #[`item.date`]="{ item }">
        <div class="d-none">{{ item.date }}</div>
          <div :class="item.read ? '' : 'font-weight-bold'">
        
        {{ item.date | moment("DD.MM.YYYY HH:mm") }}
        </div>
      </template>

    </v-data-table>
    <message-dialog :isOpenDialog="isOpenDialog" :item="dialogItem"></message-dialog>
  </div>
</template>

<script>
import MessageDialog from './MessageDialog'
 
export default {
  props: {
    isread: {
      default: false,
      type: Boolean
    },
    isarchive: {
      default: false,
      type: Boolean
    }
  },
  components: {
    MessageDialog,
  },
  
  data: () => ({
    isOpenDialog: false,
    dialogItem() {return {client: '',details:[], date: '' } },
    loading: false,
    selected: [],
    attrs: null,
    on: null,
    headers: [
      { value: 'actions', sortable: false, class:['w-fit', 'px-0'], cellClass:['w-fit', 'px-0'] },
      { value: 'name', sortable: false, cellClass:'w-fit', width: 300 },
      { value: 'text', sortable: false, cellClass:'w-100'},
      { value: 'date', sortable: false, cellClass:'w-fit', align:'center' },
    ],
    messages: [],
    search: '',
    
  }),
  created() {
    this.getMessages();
  },
  computed: {
    filterMessages() {      
      return this.messages.filter(m => m.status != 2);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    expert() {
      return this.$store.state.expert;
    },
    
  },
  watch: {
    expert() {
      this.getMessages();
    }
  },
  methods: {
    toArchive(id) {
      this.messages.find(m => m.id == id).status = 2;
    },
    getMessages() {
      this.loading = true
      let url = `/messages?user_id=${this.expert}`;
      if (this.isread) {
        url += '&is_new=1';
      }
      if (this.isarchive) {
        url += '&is_archive=1';
      }
       this.$api.get(url)
        .then(resp => {
          this.loading = false;
          this.messages = resp.data._embedded.messages;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    updateStatus(ids,status) {
      let messages = this.messages.map(m => ids.includes(m.id) ? {...m, read:status }: m);
      this.messages = messages
      let url = '/messages/status';
       this.$api.patch(url,{ids,status})
        .catch(error => {  
          let messages = this.messages.map(m => ids.includes(m.id) ? {...m, read: !m.read }: m);
          this.messages = messages
          this.$store.dispatch('addRequestError', error)
        })
    },
    markAsRead() {
      let ids = this.selected.map(m => m.id);
      if (ids.length) {
        this.updateStatus(ids,1);
      }
    },
    deleteMessages(ids) {
      let forDelete = this.messages.filter(m => ids.includes(m.id));
      let messages = this.messages.filter(m => !ids.includes(m.id));
      this.messages = messages;
      let url = '/messages';
       this.$api.delete(url,{
          data: {
            ids
          }
        })
        .catch(error => {  
          this.messages = this.messages.concat(forDelete);
          this.$store.dispatch('addRequestError', error)
        })
    }, 
    deleteSelected() {
      let ids = this.selected.map(m => m.id);
      if (ids.length) {
        this.deleteMessages(ids);
        this.selected = [];
      }
    },
    restoreMessages(ids) {
      let forRestore = this.messages.filter(m => ids.includes(m.id));
      let messages = this.messages.filter(m => !ids.includes(m.id));
      this.messages = messages;
      let url = '/messages/restore';
       this.$api.patch(url,{ids})
        .catch(error => {  
          this.messages = this.messages.concat(forRestore);
          this.$store.dispatch('addRequestError', error)
        })
    },
    restoreSelected() {
      let ids = this.selected.map(m => m.id);
      if (ids.length) {
        this.restoreMessages(ids);
        this.selected = [];
      }
    },
    openDialog(item) {
      this.dialogItem = item;
      this.isOpenDialog = true;
      this.updateStatus([item.id],1);
    }
    
  }
}
</script>

<style>

</style>