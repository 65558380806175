<template>
  <div class="messages">
    <page-title :title="'Сообщения'"></page-title>
    <v-tabs
        v-model="activeTab"
        class="custom__tabs"
        hide-slider
        show-arrows
        background-color="transparent"
      >
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          <v-icon left>mdi-email-multiple</v-icon>
          Все сообщения
        </v-tab >
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          <v-icon left>mdi-email-alert</v-icon>
          Не прочитанные          
        </v-tab>
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          <v-icon left>mdi-archive-arrow-down</v-icon>
          Архив          
        </v-tab>
    </v-tabs>
    <v-card class="pa-3 rounded-0 flat custom__card">
      <v-tabs-items touchless v-model="activeTab">
        <v-tab-item>
          <message-table v-if="activeTab === 0"></message-table>
        </v-tab-item>
        <v-tab-item>
          <message-table v-if="activeTab === 1" :isread="true"></message-table>
        </v-tab-item>
        <v-tab-item>
          <message-table v-if="activeTab === 2" :isarchive="true"></message-table>
        </v-tab-item>
      </v-tabs-items>        
    </v-card>
  </div>
</template>
<script>
import MessageTable from '../components/messages/MessageTable.vue'
import PageTitle from '../components/main/PageTitle'
export default {
  components: {
    PageTitle,
    MessageTable
  },
  data: () => ({
    activeTab: 0,
  }),
}
</script>

